jQuery(document).ready(function() {
    globalequalheight();
    equalheight('.product-item');

    jQuery("#nav-toggle").click(function(){
        jQuery('body').toggleClass("open-menu");

    });
    jQuery(document).on('click', function () {
        jQuery('body').removeClass('open-menu');

    });
    jQuery('#nav-toggle').on('click', function (e) {
        e.stopPropagation();
    });
});




// ON WINDOW LOAD
jQuery(window).load(function () {
    globalequalheight();
    equalheight('.product-item');
});


// ON WINDOW RESIZE
jQuery(window).resize(function () {
    globalequalheight();
    equalheight('.product-item');
});


/* Global function */
function globalequalheight() {
//    equalheight('.equalheight');
//    equalheight('.ptoduct-img .product-equal-img img');
    equalheight('.product-equal-img img');
    equalheight('.row .eqheight');
}



// EQUAL HEIGHT FUNCTION
equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel,
            topPosition = 0;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).height('auto');
            topPostion = jQueryel.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = jQueryel.height();
                rowDivs.push(jQueryel);
            } else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.height()) ? (jQueryel.height()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
};
